import {Card, Typography, Grid, List} from 'antd';
import {styled} from 'styled-components';
import {RightCircleOutlined} from '@ant-design/icons';
import StoredAuth from '../../../utils/localStorage';

const {Title, Paragraph} = Typography;
const {useBreakpoint} = Grid;


const Wr = styled(Card)`
  width: 90%;
  max-width: 1920px;
  margin-top: 32px;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const H1Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 28px;
    }
    @media only screen and (max-width: 425px){
      font-size: 24px;
    }
`;

const Text = styled(Paragraph)`
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  @media only screen and (max-width: 425px){
      font-size: 12px;
    }
`;

const FormWr = styled.div`
  margin: 0 auto;
  max-width: 780px;
  padding: 0 20px;
  @media only screen and (max-width: 767px){
    padding: 0;
  }
`;


export function InfoContent() {
  const userData = {...StoredAuth.get()};
  if (userData.validatorsCount === null) {
    userData.validatorsCount = 1;  //default value of validators in form
  }

  const {sm} = useBreakpoint();

  const listData = [
    {text: 'The network\'s technology stack may change.'},
    {text: 'Network restarts are possible.'},
    {text: 'There is no slashing for staked tokens.'},
  ];

  return (
    <Wr>
      <FormWr>
        <H1Title>Sanr Network Incentivized Testnet Details</H1Title>
        <Title level={!sm ? 3 :2}>About</Title>
        <Text>Santiment Network is a market intelligence layer on Ethereum, an interactive environment for traders, portfolio managers, analysts, and data providers to build reputation, monetize skills and intellectual property.</Text>
        <Text>The current network is operating in test and development mode.</Text>
        <Text>Prior to the mainnet release, anyone interested can support our testnet by running a node with a validator or in read-only mode.</Text>
        <Text>There are some unique features during the test period:</Text>
        <List
          bordered
          dataSource={listData}
          renderItem={(item) => (
          <List.Item>
            <Typography.Text><RightCircleOutlined /></Typography.Text> {item?.text} <a href={item?.link} target="_blank" rel="noreferrer">{item?.linkTitle}</a>{item?.moreText} <a href={item?.link2} target="_blank" rel="noreferrer">{item?.linkTitle2}</a>{item?.moreText2}
          </List.Item>
        )} 
          style={{marginBottom: '20px'}}
        />
        <Title level={!sm ? 3 :2}>Participation limits</Title>
        <Text>During the current stage of network testing, the limit for nodes with validators is 108. The number of nodes with validators can be tracked on the dashboard.</Text>
        <Text>There is no limit to the number of read-only nodes, but only the 108 nodes with the lowest latency to different geographical regions and fastest block updates receive rewards on a daily basis.</Text>
        <Title level={!sm ? 3 :2}>Reward</Title>
        <Text>For validators 52% APY on the staked SAN token.</Text>
        <Text>Opportunity to earn points, which will be converted into tokens upon the mainnet launch..</Text>
        <Title level={!sm ? 3 :2}>Start run node with validator</Title>
        <Text><span style={{fontWeight: 600}}>Important!</span> If the owner of a node with validators does not perform updates on the network in a timely manner, the receipt of rewards is suspended and there is a risk of being excluded from the <a href="https://santiment.network/" target="_blank" rel="noreferrer">santiment.network</a> testing program. Also, point rewards could be changed in the future.</Text>
      </FormWr>
    </Wr>
  )
}