import {useState, useEffect} from 'react';
import {styled} from 'styled-components';
import {Header} from './components/Header';
import {Content} from './components/Content';
import {Footer} from './components/Footer'
import './App.css';
import bgImg from '../src/img/bg.png';
import {checkIfWalletIsConnected, personalSign, getMessageToSign, getAuthToken, isMobileDevice} from './utils/MetaMaskAuth';
import StoredAuth from './utils/localStorage';
import {getNodeHolders} from './utils/getNodeHolders';
import {getNodeHolder} from './utils/getNodeHolder';
import {Modal} from 'antd';
import {FormContent, InfoContent, ReadOnlyContent, ValidatorsContent} from './components/PopupsContent';
import {CloseCircleOutlined} from '@ant-design/icons';
import {ButtonAction} from './components/ButtonAction/index';


const Wr = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-flow: column;
  background: url(${bgImg});
  /* background-color: #182944; */
  /* background-color: #192e4c; */
  background-repeat: no-repeat;
  background-position: center -250px;
  @media (min-width: 1920px) {
    background-size: cover;
  }
`;

const FormWr = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;


function App() {

  const [userAddress, setUserAddress] = useState(StoredAuth.get()?.address !== null ? StoredAuth.get()?.address?.toLowerCase() ?? null : null);
  const [signedToken, setSignedToken] = useState(StoredAuth.get()?.accessToken || null);
  const [isSignPopupShown, setIsSignPopupShown] = useState(false);
  const [holders, setHolders] = useState([]);
  const [isHolder, setIsHolder] = useState(false);
  const [isConnected, setIsConnected] = useState(false);



  const clear = () => {
    StoredAuth.set(null);
    setUserAddress(null);
    setSignedToken(null);
  };

  function isTokenExpired(token) {
    const jwtPayload = token ? JSON.parse(window.atob(token?.split('.')[1])) : 0;
    return Date.now() >= jwtPayload.exp * 1000;
  }

  useEffect(() => {
    if (isTokenExpired(signedToken)) {
      clear();
    }

    (async () => {
      try {
        const {data: holdersList} = await getNodeHolders();
        setHolders(holdersList.data);
        if (signedToken !== null) {
          const {data: holder} = await getNodeHolder(signedToken);
          setIsHolder(holder?.last_block_number > 0);
          console.log('HOLDER', holder);
        }
      } catch (e) {
        console.log('error in SIGNED TOKEN block', e);
        clear();
      }
    })();
  }, [signedToken]);

  useEffect(() => {
    (async () => {
      try {
        if (StoredAuth.get()?.accessToken !== null && StoredAuth.get()?.address !== null) {
          !window.ethereum.isConnected() && clear();
          const connectUser = await checkIfWalletIsConnected();
          setUserAddress(connectUser);
        }
      } catch (e) {
        console.log('ERROR first EFFECT on PAGE', e);
        clear();
      }
    })();
  }, [])


  useEffect(() => {
    if (!window.ethereum && !isMobileDevice()) {
      clear();
      return;
    } else {
      if (!isMobileDevice()) {
        const f = ({0: account}) => {
          if (!account || account?.toLowerCase() !== userAddress) {
            clear();
            window.location.reload();
            return;
          }
        };
        window.ethereum.on('accountsChanged', f);
        window.ethereum.on('disconnect', f);
        return () => {
          window.ethereum.removeListener('accountsChanged', f);
          window.ethereum.removeListener('disconnect', f);
        };
      }
    }
  }, [userAddress]);

  useEffect(() => {
    if (userAddress === null) {
      return;
    }
    if (window.ethereum?.isConnected() && userAddress) {
      setIsConnected(true);
    }
    (async () => {
      try {
        if (signedToken === null && window.ethereum) {
          const {messageToSign} = await getMessageToSign();
          console.log('getToSign', messageToSign);
          setIsSignPopupShown(true);
          const {signedMessage} = await personalSign(messageToSign);
          console.log('signedMessagesdgfdfgfdfgfg', signedMessage)
          const {data} = await getAuthToken(signedMessage, messageToSign);

          setIsSignPopupShown(false);
          StoredAuth.set({...data});
          setSignedToken(data?.accessToken);
        }
      } catch (e) {
        console.log('error in SIGNED EFFECT block', e);
        clear();
        setIsSignPopupShown(false);
      }
    })();

  }, [signedToken, userAddress]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [isValidatorsPopupOpen, setIsValidatorsPopupOpen] = useState(false);
  const [isReadOnlyPopupOpen, setIsReadOnlyPopupOpen] = useState(false);

  const modalWindows = [
    {id: 'formModal', isOpen: isPopupOpen, setIsOpen: setIsPopupOpen, closeText: 'Close Form', element: <FormWr><FormContent isHolder={isHolder} signedToken={signedToken} isFileUpload={isFileUpload}/></FormWr>},
    {id: 'infoModal', isOpen: isInfoPopupOpen, setIsOpen: setIsInfoPopupOpen, closeText: 'Close', element: <FormWr><InfoContent /></FormWr>},
    {id: 'validatorsModal', isOpen: isValidatorsPopupOpen, setIsOpen: setIsValidatorsPopupOpen, closeText: 'Close', element: <FormWr><ValidatorsContent signedToken={signedToken} userAddress={userAddress} setUserAddress={setUserAddress} isSignPopupShown={isSignPopupShown} isConnected={isConnected} text="Register validator" isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} setIsFileUpload={setIsFileUpload} setIsValidatorsPopupOpen={setIsValidatorsPopupOpen}  setIsReadOnlyPopupOpen={setIsReadOnlyPopupOpen}/></FormWr>},
    {id: 'nodesModal', isOpen: isReadOnlyPopupOpen, setIsOpen: setIsReadOnlyPopupOpen, closeText: 'Close', element: <FormWr><ReadOnlyContent signedToken={signedToken} userAddress={userAddress} setUserAddress={setUserAddress} isSignPopupShown={isSignPopupShown} isConnected={isConnected} text="Register node" isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} setIsFileUpload={setIsFileUpload} setIsReadOnlyPopupOpen={setIsReadOnlyPopupOpen}/></FormWr>},
  ];


  return (
    <Wr className="App">
      {signedToken !== 0 && 
      modalWindows.map(m =>
      <Modal
        key={m.id}
        closeIcon={<CloseCircleOutlined />} 
        open={m.isOpen}
        onOk={() => {m.setIsOpen(!m.isOpen)}}
        onCancel={() => {m.setIsOpen(!m.isOpen)}}
        footer={() => (
          <>
            <FormWr><ButtonAction colorType="secondary" onClickPress={() => {m.setIsOpen(!m.isOpen)}} text={m.closeText}/></FormWr>
          </>
        )}
        width={1000}
      >
        {m.element}
      </Modal>)}
        <Header isSignPopupShown={isSignPopupShown} isInfoPopupOpen={isInfoPopupOpen} setIsInfoPopupOpen={setIsInfoPopupOpen}/>
        <Content
          isSignPopupShown={isSignPopupShown}
          signedToken={signedToken}
          setUserAddress={setUserAddress}
          setIsPopupOpen={setIsPopupOpen}
          setIsFileUpload={setIsFileUpload}
          isConnected={isConnected} 
          userAddress={userAddress}
          holders={holders}
          isValidatorsPopupOpen={isValidatorsPopupOpen}
          setIsValidatorsPopupOpen={setIsValidatorsPopupOpen}
          isReadOnlyPopupOpen={isReadOnlyPopupOpen}
          setIsReadOnlyPopupOpen={setIsReadOnlyPopupOpen}
        />
      <Footer />
    </Wr>
  );
}

export default App;
