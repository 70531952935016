import {styled} from 'styled-components';
import imgLogo from '../../img/s_logo.svg';
import {SignPopup} from '../SignPopup';
import {ButtonAction} from '../ButtonAction/index';


const Wr = styled.div`
  padding: 20px;
  width: 70%;
  /* height: 860px; */
  max-width: 1920px;
  display: flex;
  align-items: center;
  flex-flow: column;
  @media only screen and (max-width: 767px){
    width: 90%;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const Logo = styled.img`
  padding-top: 8%;
  width: 100%;
  max-width: 235px;
  filter: brightness(0) invert(1);
  @media only screen and (max-width: 767px){
    width: 80%;
  }
`;

const TitleWr = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap:0;
`;

const Title = styled.div`
  margin: 76px 0 40px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 56px;
  font-weight: bold;
  text-align: center;
  overflow: visible;
  @media only screen and (max-width: 767px){
    margin: 24px 0;
    font-size: 36px;
    }
  @media only screen and (max-width: 425px){
      font-size: 28px;
    }
`;

const ButtonWr = styled.div`
  margin-top: 32px 0;
  padding: 0 0 80px;
  @media only screen and (max-width: 767px){
    margin: 24px 0;
    padding: 24px 0 64px;
    }
  @media only screen and (max-width: 425px){
    margin: 24px 0;

    }
`;

export function Header({isSignPopupShown, isInfoPopupOpen, setIsInfoPopupOpen}) {
  return (
    <Wr>
      <Logo src={imgLogo} />
      {isSignPopupShown 
      ? <SignPopup/> 
      : 
        <TitleWr>
          <Title>Welcome to the Santiment Network node dashboard</Title>
          <ButtonWr>
            <ButtonAction isPopupOpen={isInfoPopupOpen} setIsPopupOpen={setIsInfoPopupOpen} text="Testnet details"/>
          </ButtonWr>
        </TitleWr>}
    </Wr>
  )
}
